<ngx-spinner></ngx-spinner>
<div class="header-navbar">
  <igx-navbar>
    <div igxNavbarTitle>
      <div class="buttons">
        <div class="button">
          <button igxButton="flat" igxRipple="#fdfdfd" (click)="onHomeClick()" [translate]>Home</button>
        </div>
        <div class="button">
          <button [igxToggleAction]="Production" [igxDropDownItemNavigation]="Production" igxButton="flat"
            igxRipple="#fdfdfd" [disabled]="serviceStatus.Production || serviceAuth.Production" [translate]>
            Production
          </button>

          <igx-drop-down class="drop-down" #Production (onSelection)="onSelection($event)">
            <ul>
              <div class="headtopic"></div>

              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of Production1" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of Production2" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of Production3" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </igx-drop-down>
        </div>
        <div class="button">
          <button [igxToggleAction]="inventory" [igxDropDownItemNavigation]="inventory" igxButton="flat"
            igxRipple="#fdfdfd" [disabled]="serviceStatus.Inventory  || serviceAuth.Inventory" [translate]>
            InventorySystem
          </button>

          <igx-drop-down class="drop-down" #inventory (onSelection)="onSelection($event)">
            <ul>
              <div class="headtopic"></div>
              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of Inventory" [value]="item.text"
                (click)="onClick(item.navigation)" [hidden]="item.text == 'Serial' && userInLocalStorage.superAdmin != 'stradmin' ? true : false " [translate]>  
                <!-- (click)="onClick(item.navigation)"  [translate]> [hidden]="item.text == 'Serial' && userInLocalStorage.superAdmin != 'stradmin' ? true : false " -->
                  {{ item.text }}
                </button>
              </li>
              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of Inventory2" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </igx-drop-down>
        </div>

        <div class="button">
          <button [igxToggleAction]="Purchase" [igxDropDownItemNavigation]="Purchase" igxButton="flat"
            igxRipple="#fdfdfd" [disabled]="serviceStatus.Purchase || serviceAuth.Purchase" [translate]>
            PurchaseSystem
          </button>

          <igx-drop-down class="drop-down" #Purchase (onSelection)="onSelection($event)">
            <ul>
              <li>
                <div class="headtopic">
                  <div class="topic" [translate]>Product</div>
                </div>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of Purchase1" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
              <li>
                <div class="headtopic">
                  <div class="topic" [translate]>Service</div>
                </div>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of Purchase2" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </igx-drop-down>
        </div>

        <div class="button">
          <button [igxToggleAction]="Sale" [igxDropDownItemNavigation]="Sale" igxButton="flat" igxRipple="#fdfdfd"
            [disabled]="serviceStatus.Sale || serviceAuth.Sale" [translate]>
            SaleSystem
          </button>

          <igx-drop-down class="drop-down" #Sale (onSelection)="onSelection($event)">
            <ul>
              <li>
                <div class="headtopic">
                  <div class="topic" [translate]>Product</div>
                </div>

                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of Sale1" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
              
              <li *ngIf="perpetualMode != 'Y'">
                <div class="headtopic">
                  <div class="topic" [translate]>Service</div>
                </div>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of Sale2" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
              <li *ngIf="perpetualMode == 'Y'">
                <div class="headtopic">
                  <div class="topic" [translate]>Service</div>
                </div>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of Sale3" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </igx-drop-down>
        </div>

        <div class="button">
          <button [igxToggleAction]="Payment" [igxDropDownItemNavigation]="Payment" igxButton="flat" igxRipple="#fdfdfd"
            [disabled]="serviceStatus.Payment  || serviceAuth.Payment" [translate]>
            PaymentSystem
          </button>

          <igx-drop-down class="drop-down" #Payment (onSelection)="onSelection($event)">
            <ul>
              <div class="headtopic"></div>
              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of PaymentSystem1" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of PaymentSystem2" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </igx-drop-down>
        </div>

        <div class="button">
          <button [igxToggleAction]="Receive" [igxDropDownItemNavigation]="Receive" igxButton="flat" igxRipple="#fdfdfd"
            [disabled]="serviceStatus.Receive  || serviceAuth.Receive" [translate]>
            ReceiveSystem
          </button>

          <igx-drop-down class="drop-down" #Receive (onSelection)="onSelection($event)">
            <ul>
              <div class="headtopic"></div>

              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of ReceiveSystem" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </igx-drop-down>
        </div>

        <div class="button">
          <button [igxToggleAction]="Financial" [igxDropDownItemNavigation]="Financial" igxButton="flat"
            igxRipple="#fdfdfd" [disabled]="serviceStatus.Financial || serviceAuth.Financial" [translate]>
            FinancialSystem
          </button>

          <igx-drop-down class="drop-down" #Financial (onSelection)="onSelection($event)">
            <ul>
              <div class="headtopic"></div>
              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of FinancialSystem1" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of FinancialSystem2" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </igx-drop-down>
        </div>

        <div class="button">
          <button [igxToggleAction]="Account" [igxDropDownItemNavigation]="Account" igxButton="flat" igxRipple="#fdfdfd"
            [disabled]="serviceStatus.Account || serviceAuth.Account" [translate]>
            AccountSystem
          </button>

          <igx-drop-down class="drop-down" #Account (onSelection)="onSelection($event)">
            <ul>
              <div class="headtopic"></div>
              <li *ngIf="perpetualMode != 'Y' ">
                <button  igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of AccountSystem1" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
              <li *ngIf="perpetualMode == 'Y' ">
                <button  igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of AccountSystem1ForPerpetual" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
              <!-- <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of AccountSystem2" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li> -->
            </ul>
          </igx-drop-down>
        </div>

        <div class="button">
          <button [igxToggleAction]="SystemTax" [igxDropDownItemNavigation]="SystemTax" igxButton="flat"
            igxRipple="#fdfdfd" [disabled]="serviceStatus.SystemTax || serviceAuth.SystemTax" [translate]>
            SystemTax
          </button>

          <igx-drop-down class="drop-down" #SystemTax (onSelection)="onSelection($event)">
            <ul>
              <li>
                <div class="headtopic">
                  <div class="topic" [translate]>TaxAmount</div>
                </div>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of SystemTax1" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
                <div class="headtopic">
                  <div class="topic" [translate]>WithholdingTax</div>
                </div>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of SystemTax2" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
                <div class="headtopic">
                  <div class="topic" [translate]>Other</div>
                </div>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of SystemTax3" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </igx-drop-down>
        </div>

        <div class="button">
          <button [igxToggleAction]="systemAsset" [igxDropDownItemNavigation]="systemAsset" igxButton="flat"
            igxRipple="#fdfdfd" [disabled]="serviceStatus.SystemAsset || serviceAuth.SystemAsset" [translate]>
            SystemAsset
          </button>

          <igx-drop-down class="drop-down" #systemAsset (onSelection)="onSelection($event)">
            <ul>
              <div class="headtopic"></div>

              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of SystemAsset" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </igx-drop-down>
        </div>

        <div class="button">
          <button [igxToggleAction]="systemBudget" [igxDropDownItemNavigation]="systemBudget" igxButton="flat"
            igxRipple="#fdfdfd" [disabled]="serviceStatus.Payment || serviceAuth.Payment" [translate]>
            SystemBudget
          </button>

          <igx-drop-down class="drop-down" #systemBudget (onSelection)="onSelection($event)">
            <ul>
              <div class="headtopic"></div>

              <li>
                <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of SystemBudget" [value]="item.text"
                  (click)="onClick(item.navigation)" [translate]>
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </igx-drop-down>
        </div>
      </div>
    </div>

    <button [igxToggleAction]="mobile" [igxDropDownItemNavigation]="search" igxButton="icon"
      [overlaySettings]="overlaySettings">
      <igx-icon>phone_iphone</igx-icon>
    </button>
    <!-- <img class="img" src="assets//media/mobile_icon/AccCloud_Mobile.png" /> -->
    <igx-drop-down #mobile>
      <div class="row_mobile">
        <div class="column_mobile">
          <img class="img_mobile" src="assets//media/mobile_icon/AccCloud_Mobile.png" />
          <button igxButton="flat">
          <a href="https://mobile.acccloud.me/login" target="_blank">Mobile</a>
        </button>
    
          <img class="img_mobile" src="assets//media/mobile_icon/AccCloud_Line_Approve.png" />
          <button igxButton="flat">
            <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=accconnect" target="_blank">Line<br>Approve</a>
          </button>

          <img class="img_mobile" src="assets//media/mobile_icon/AccCloud_ Production Tracking.png" />
          <button igxButton="flat">
            <a href="https://play.google.com/store/apps/details?id=com.str.accclound" target="_blank">Production Tracking</a>
          </button>
          
        </div>
        <div class="column_mobile">
          <img class="img_mobile" src="assets//media/mobile_icon/AccCloud_Payroll.png" />
          <button igxButton="flat">
            <a href="https://play.google.com/store/apps/details?id=acccloud.acctime.payrollmobile" target="_blank">Payroll</a>
          </button>
    
          <img class="img_mobile" src="assets//media/mobile_icon/AccCloud_POS.png" />
          <button igxButton="flat">
            <a href="https://play.google.com/store/apps/details?id=acccloude.me.acccloud_pos" target="_blank">POS</a>
          </button>
        </div>
      </div>
    </igx-drop-down>
      


    <button [igxToggleAction]="search" [igxDropDownItemNavigation]="search" igxButton="icon"
      [overlaySettings]="overlaySettings">
      <igx-icon>search</igx-icon>
    </button>
    <igx-drop-down class="drop-down" #search (onSelection)="onSelection($event)">
      <ul>
        <li>
          <button igxButton="flat" igxRipple="#fdfdfd" *ngFor="let item of SystemSearch" [value]="item.text"
            (click)="onClick(item.navigation)" [translate]>
            {{ item.text }}
          </button>
          <button igxRipple="#fdfdfd" (click)="dropDown.open()" igxButton="flat" [translate]>
            Document
          </button>
        </li>
      </ul>
    </igx-drop-down>
    <button style="padding-right: 0px; margin-right: 0px;" igxButton="icon" igxRipple="#fdfdfd" [igxToggleAction]="more"
      [igxDropDownItemNavigation]="more" [overlaySettings]="overlaySettings">
      <igx-icon>more_vert</igx-icon>
    </button>
    <igx-drop-down #more [width]="'300px'" (onSelection)="onSelection($event)">
      <div class="drop-down-icon" style="vertical-align: middle">

        <article class="avatars-wrapper">
          <div class="avatar-sample icon">
            <igx-avatar *ngIf="superAdmin" icon="person" bgColor="yellow" color="blue" [roundShape]="true"
              size="medium"></igx-avatar>
            <igx-avatar *ngIf="!superAdmin" icon="person" [roundShape]="true" size="medium"></igx-avatar>
          </div>
        </article>


        <div class="drop-down-user">
          <span style="margin-left: 20px;">{{ 'CompanyName' | translate }} : {{this.companyName}}</span>
        </div>
        <div class="drop-down-user">
          <span style="margin-left: 20px;">{{ 'UserName' | translate }} : {{this.userName}}</span>
        </div>
        <button igxButton="flat" igxRipple="#fdfdfd" (click)="onClick('center/upload-picture-master')" [translate]>
          SearchFile
          <igx-icon class="padding-button-icon">image</igx-icon>
        </button>
        <button igxButton="flat" igxRipple="#fdfdfd" (click)="onClick('center/report-request')" [translate]>
          Report Request
        </button>
        <button igxButton="flat" igxRipple="#fdfdfd" [igxToggleAction]="lang" [igxDropDownItemNavigation]="lang">
          Language
          <igx-icon class="padding-button-icon">language</igx-icon>
        </button>
        <igx-drop-down #lang (onSelection)="onSelection($event)" [width]="'300px'">
          <div class="drop-down-icon">
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('en-US')">
              English
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('th-TH')">
              Thai
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('cn-CN')">
              Chinese
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('vn-VN')">
              Vietnamese
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('jp-JP')">
              Japanese
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('la-LA')">
              Laos
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('ae-AE')">
              Arabic
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('de-DE')">
              German
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('es-ES')">
              Spanish
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('in-IN')">
              Hindi
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('kr-KR')">
              Korean
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('pt-PT')">
              Portuguese
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('ru-RU')">
              Russian
            </button>
            <button igxButton="flat" style="color:#9932cc; width: 33%; " igxRipple="#9932cc" (click)="changeLanguage('kh-KH')">
              Cambodian
            </button>

          </div>
        </igx-drop-down>
        <button igxButton="flat" igxRipple="#fdfdfd" (click)="onClick('login')" [translate]>
          Logout
          <igx-icon class="padding-button-icon">logout</igx-icon>
        </button>
      </div>
    </igx-drop-down>
    <igx-combo #dropDown  igxRipple="#fdfdfd" [hidden]="true" [itemsMaxHeight]="250" [itemsWidth]="'700px'"
      [data]="menuMasterModel" [overlaySettings]="customSettings" (selectionChanging)="handleSelection($event)"
      displayKey="menuName" valueKey="menuMasterId" placeholder="{{ 'Quick Search' | translate }}"
      >
    </igx-combo>
  </igx-navbar>
</div>
<igx-banner class="offline-banner" #connectionBanner>
  {{ bannerText | translate }}
</igx-banner>
